import React, { Component, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

import componentStyles from "./style.module.css"
import API from '../../js/api'

import Node from '../Node'

const SimilarVectors = ({title, vectors, slug, tags}) => {
  if(vectors == null){
    vectors = Array.apply(null, Array(20));
  }
  return(
    <div className={componentStyles.SimilarVectors}>
      <div className={componentStyles.smallTitleCarrier}>
        <h2>Similar {API.get("contentType")}</h2>
        <Link to={`/${API.get("searchUrlPath")}/${slug}/`}>
          <div className={componentStyles.seeMore}>
            <span>See More</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
          </div>
        </Link>
      </div>
      <p className="description">{API.get("similarDescription", [title])} {!tags && "a simpler, general or shorter form of the word."}{tags && "this similar words: "}{tags && tags.split(",").map((tag, si) => {
        if(si > 6){
          return;
        }
        if(si != 0){
          return ([", ", <Link key={"tag-"+si} to={`/${API.get("searchUrlPath")}/${tag.replace(/ /g, "-").toLowerCase()}/`}>{tag.replace(/-/g, " ").toLowerCase()}</Link>]);
        }else{

        }
        return (<Link key={"tag-"+si} to={`/${API.get("searchUrlPath")}/${tag.replace(/ /g, "-").toLowerCase()}/`}>{tag.replace(/-/g, " ").toLowerCase()}</Link>)
      })}</p>
      <div className="nodeListing">
        {vectors.map((res, i) => {
          return <Node key={res ? res.id : i} vector={res} loading={res == null ? true : false}/>
        })}
      </div>
    </div>
  );
}

export default SimilarVectors;
