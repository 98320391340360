import React from "react"
import { graphql, Link, navigate } from "gatsby"

import titleCase from '../js/titleCase';
import API from '../js/api';
import makeid from '../js/makeid';

import Layout from "../layouts/layout"
import SEO from "../components/SEO/index.js"
import PostItem from "../components/Blog/PostItem"
import BreadCrumbs from "../components/BreadCrumbs"
import Native from "../components/Native"
import SimilarVectors from "../components/SimilarVectors"
import CollectionVectors from "../components/CollectionVectors"

class SVG extends React.Component {
  constructor(props){
    super(props);
    let id = "unset";
    let slug = "unset";
    if(typeof document != "undefined"){
      let pathname = document.location.pathname;
      let searchQ = document.location.search;
      if(searchQ.includes("?q=cache")){
        if(typeof window != "undefined"){
          navigate("/svg/" + searchQ.split(":https://www.svgrepo.com/svg/")[1].split("+")[0]);
        }
      }
      if(pathname){
        id = pathname.split("/")[2];
        slug = pathname.split("/")[3];
      }
    }
    this.state = {
      id, slug, saved: API.checkSavedStatus(id), sentUrl: undefined
    }
  }

  componentDidMount(){
    this.fetchIcon(this.state.id, this.state.slug);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let pathname = this.props.location.pathname;
      let id = 1;
      let slug = "";
      if(pathname){
        id = pathname.split("/")[2];
        slug = pathname.split("/")[3];
      }
      this.setState({id, slug, icon: null}); // add similar schafalding here
      this.fetchIcon(id, slug);

    }
  }

  fetchIcon(id, slug){
    if(typeof window != "undefined"){
      fetch(`https://api.svgrepo.com/svg/${id}/${slug}`).then(res => res.json()).then(res => {
        if(res.status == "success"){
          this.setState({
            icon: res.icon,
            saved: API.checkSavedStatus(id)
          })
          this.fetchCollection(res.icon.data_pack);
        }else{
          navigate("/404");
        }
      })
    }
  }

  fetchCollection(collection){
    if(typeof window != "undefined"){
      fetch(`https://api.svgrepo.com/collection/?term=${collection}&limit=20`).then(res => res.json()).then(res => {
        if(res.count){
          this.setState({
            collection: res.icons
          })
        }
      })
    }
  }


  getSvgUrl(id, slug){
    let svgUrl = `https://www.${API.get("assetShowSuffix") == "svg" ? "png" : "svg"}repo.com/svg/${id}/${slug}`;
    if(this.state.sentUrl){
      return this.state.sentUrl;
    }
    return svgUrl;
  }

  goToSvgUrl(id, slug){
    let svgUrl = `https://www.${API.get("assetShowSuffix") == "svg" ? "png" : "svg"}repo.com/svg/${id}/${slug}`;
    var win = window.open(svgUrl, '_blank');
    win.focus();
  }

  render() {
    let slug, title = null;
    if(this.state.slug == "unset"){
      slug = "!#-slug-single-#!";
      title = "!#-title-single-#!";
    }else{
      slug = this.state.slug;
      if(slug){
        title = titleCase(slug.replace(/-/g, " ").split("&")[0]);
      }
    }

    let id = null;
    if(this.state.id == "unset"){
      id = "!#-id-single-#!";
    }else{
      id = this.state.id;
    }

    let icon = this.state.icon;
    let same = icon ? icon.same : "";
    if(same == 1){
      same = null;
    }
    let sameSpace = "";
    if(same){
      sameSpace = " "+ same;
    }

    let packTitle = API.get("contentType");
    let packLink = "/collections/all/1";
    if(icon){
      if(icon.data_pack){
        packTitle = titleCase(icon.data_pack.replace(/-/g, " "));
        packLink = "/collection/"+icon.data_pack+"/";
      }
    }

    return (
      <Layout>
        <BreadCrumbs
          links={[
            {title: "Home"},
            {title: packTitle, link: packLink},
            {title: title, link: `/svg/${id}/${slug}` }
          ]}
        />
        <h1 className="title">{API.get("singleTitle", [title])} {same && <span>{same}</span>}</h1>
        <p className="description">
          {API.get("singleDesc", [title, sameSpace])}
        </p>
        <div className="contentListing" style={{justifyContent: "flex-start", alignItems: "center"}}>
          <div className="featuredImageCarrier">
            {slug &&
              <img
                src={`${API.get("assetShowPrefix")}${id}/180/${slug}.${API.get("assetShowSuffix")}`}
                intrinsicsize={"512 x 512"}
                width={180}
                height={180}
                srcSet={`${API.get("assetShowPrefix")}${id}/512/${slug}.${API.get("assetShowSuffix")} 4x`}
                alt={API.get("singleImageAlt", [title])}
                title={API.get("singleImageAlt", [title])}/>
            }
          </div>

          <div className="details">
            <ul>
              <li><span>COLLECTION:</span> <Link to={packLink}>{packTitle}</Link></li>
              <li><span>LICENSE:</span> <Link to={"/page/licensing"}>{icon ? (icon.license ? icon.license : "CC0") : ""} License</Link></li>
              {icon ? (icon.license ?
                <li><span>AUTHOR:</span> <a href={icon ? icon.license_link: ""} target="_blank" rel="nofollow">{icon ? icon.license_owner : ""}</a></li>
                :
                <li><span>UPLOADER:</span> {API.get("assetIdentifier")} Repo</li>
              ) : <li><span>UPLOADER:</span> {API.get("assetIdentifier")} Repo</li>}
              {icon && icon.tags &&
                <li className="detailTags"><span>TAGS:</span> {icon.tags.split(",").map((tag, si) => {
                  if(si > 6){
                    return;
                  }
                  if(si != 0){
                    return ([", ", <Link key={"tag-"+si} to={`/vectors/${tag.replace(/ /g, "-").toLowerCase()}/`}>{tag.replace(/-/g, " ").toLowerCase()}</Link>]);
                  }else{

                  }
                  return (<Link key={"tag-"+si} to={`/vectors/${tag.replace(/ /g, "-").toLowerCase()}/`}>{tag.replace(/-/g, " ").toLowerCase()}</Link>)
                })}</li>
              }
              <li><span>{API.get("assetShowSuffix") == "svg" ? "PNG" : "SVG"}:</span> <a href={this.getSvgUrl(id, slug)} onClick={() => this.goToSvgUrl(id, slug)}>Get this icon in {API.get("assetShowSuffix") == "svg" ? "PNG" : "SVG"} format</a></li>
            </ul>

            <div className="buttons">
              <a href={`${API.get("assetDownloadPrefix")}${id}/${slug}.${API.get("assetDownloadSuffix")}`}>
                <div className="button buttonActive">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>
                  <span>Download {API.get("assetIdentifier")}<span> File</span></span>
                </div>
              </a>
              <div className="button" onClick={() => { API.saveVector(id, slug); this.setState({saved: !this.state.saved}); }}>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000" fill={this.state.saved ? "#767d82": "transparent"} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                <span>{this.state.saved ? "Saved": "Save"}</span>
              </div>
            </div>
          </div>
        </div>

        <Native id={"native-js-single-mid-"+id} sendUrl={(url) => { this.setState({sentUrl: url}); }}/>
        <SimilarVectors vectors={icon ? icon.similar : null} title={title} slug={slug} tags={icon && icon.tags}/>
        <Native id={"native-js-single-bottom-"+id}/>

        <CollectionVectors vectors={this.state.collection ? this.state.collection : null} iconTitle={title} packTitle={packTitle} packLink={packLink}/>
        <SEO meta={{
          title: `${API.get("singleMetaTitle", [title])}${same ? " ("+same+")" : ""}`,
          description: API.get("singleMetaDesc", [title, same]),
          slug: `https://${API.get("domain")}/svg/${id}/${slug}`,
          breadCrumbs: [{
            to: `/page/1/`,
            title: `SVG Vectors`
          },{
            to: `/${API.get("searchUrlPath")}/${slug}/`,
            title: API.get("collectionTitle", [title])
          }]
        }} />
      </Layout>
    )
  }
}

export default SVG
